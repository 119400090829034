<template>
  <div class="d-flex justify-center align-center" style="overflow: scroll; ">
    <v-dialog v-model="dialogAguarde" persistent width="13vw">
      <div
        class="d-flex justify-center align-center"
        style="height: 60px;background-color: azure;"
      >
        <span>Buscando dados...</span>
        <v-progress-circular
          :width="3"
          color="green"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-dialog>
    <div class="d-flex align-center justify-center flex-wrap">
      <div
        class="mt-3"
        style="width: 18vw;"
        v-for="(area, ind) of resultadosAreas"
        :key="ind"
      >
        <div
          class="d-flex flex-row justify-center align-center pa-1"
          style="border: 1px solid #BDBDBD; width: 325px; background-color: #1976D2; color:white; border-radius: 5px;"
        >
          <span>{{ area.filial }}</span>
        </div>
        <div
          class="d-flex flex-row justify-space-between pa-1"
          style="border: 1px solid #BDBDBD; width: 325px; background-color: #1976D2; color:white;"
        >
          <span>Mês</span>
          <span>Meta</span>
          <span>%</span>
        </div>
        <div v-for="(item, index) in area.resultTrimestre" :key="index">
          <div
            class="d-flex flex-row justify-space-between align-center pa-1"
            v-for="(itens, i) in metas.filter(m => m.trimestre === index + 1)"
            :key="i"
            style="border: 1px solid #BDBDBD; width: 325px;"
          >
            <span class="caption" style="width: 70px;">{{ itens.mes }}</span>
            <span
              v-if="itens.metaCurr > 0"
              class="caption"
              style="display: flex; justify-content: end;align-items: end;width: 100px;"
            >
              {{
                (item.valorCurr / 3).toLocaleString("pt-BR", {
                  currency: "BRL",
                  style: "currency",
                })
              }}</span
            >
            <span
              v-else
              class="caption"
              style="display: flex; justify-content: end;align-items: end;width: 100px;"
              >-</span
            >
            <span
              class="caption"
              style="display: flex; justify-content: end;align-items: end;width: 60px;"
              >-</span
            >
          </div>
          <div
            v-if="item.valorCurr > 0"
            class="d-flex flex-row justify-space-between pa-1"
            style="border: 1px solid #BDBDBD; width: 325px; background-color: #BBDEFB;"
          >
            <span>{{ item.title }}</span>
            <span>{{ item.valor }}</span>
            <span>{{ item.percent }} %</span>
          </div>
          <div
            v-else
            class="d-flex flex-row justify-space-between pa-1"
            style="border: 1px solid #BDBDBD; width: 325px; background-color: #BBDEFB;"
          >
            <span>{{ item.title }}</span>
            <span>-</span>
            <span>-</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { stringToBrMoney } from "@/utils/stringParse";

import axios from "axios";
import { add, sub } from "date-fns";
import { mapState } from "vuex";

export default {
  name: "calcArea",
  computed: {
    ...mapState({
      headersFaturamento: state => state.metasOpimed.headersFaturamento,
      indiceReajuste: state => state.metasOpimed.indiceReajuste,
      ajustes: state => state.metasOpimed.ajustes,
      tabMetas: state => state.metasOpimed.tabMetas,
      trimestre: state => state.metasOpimed.trimestre,
      dadosCalculo: state => state.metasOpimed.dadosCalculo,
      anoEscolhido: state => state.metasOpimed.anoEscolhido,
      area: state => state.metasOpimed.area,
    }),
  },
  data: () => {
    return {
      dialogAguarde: true,
      tabFaturamento: [],
      name: "Resumo de área",
      resultadosAreas: [],
      resultTrimestre: [
        { title: "1 Trimestre", valor: 0, percent: 0, trimestre: 1 },
        { title: "2 Trimestre", valor: 0, percent: 0, trimestre: 2 },
        { title: "3 Trimestre", valor: 0, percent: 0, trimestre: 3 },
        { title: "4 Trimestre", valor: 0, percent: 0, trimestre: 4 },
      ],
      metas: [
        { mes: "Janeiro", meta: 0, percent: 0, trimestre: 1 },
        { mes: "Fevereiro", meta: 0, percent: 0, trimestre: 1 },
        { mes: "Março", meta: 0, percent: 0, trimestre: 1 },
        { mes: "Abril", meta: 0, percent: 0, trimestre: 2 },
        { mes: "Maio", meta: 0, percent: 0, trimestre: 2 },
        { mes: "Junho", meta: 0, percent: 0, trimestre: 2 },
        { mes: "Julho", meta: 0, percent: 0, trimestre: 3 },
        { mes: "Agosto", meta: 0, percent: 0, trimestre: 3 },
        { mes: "Setembro", meta: 0, percent: 0, trimestre: 3 },
        { mes: "Outubro", meta: 0, percent: 0, trimestre: 4 },
        { mes: "Novembro", meta: 0, percent: 0, trimestre: 4 },
        { mes: "Dezembro", meta: 0, percent: 0, trimestre: 4 },
      ],
    };
  },
  methods: {
    async buscaIndice() {
      const dadosCalc = this.dadosCalculo[0].itens[0];

      this.resultadosAreas = [];

      if (dadosCalc.ZXD_TIPO === "PP") {
        dadosCalc.ZXD_TIPO = "LJ";
      }
      //Consultar a tabela de espelho de calculo

      this.indiceBanco = [];
      this.travaCampos = false;
      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;
      let dados = {};

      // dados = {
      //   FIELDS: "ZXE_ANOTRI,ZXE_AREA,ZXE_META",
      //   WHERE: `ZXE_AREA LIKE 'A%' AND LEFT(ZXE_ANOTRI,4)='${this.anoEscolhido}' `,
      //   TABELA: "ZXE",
      // };

      // const areasMetas = await axios.post(url, dados);

      // const areas = [...new Set(areasMetas.data.map(e => e.ZXE_AREA.trim()))];

      const filiais = this.area[0].itens;

      for (const filial of filiais) {
        this.metas = [
          { mes: "Janeiro", meta: 0, percent: 0, trimestre: 1 },
          { mes: "Fevereiro", meta: 0, percent: 0, trimestre: 1 },
          { mes: "Março", meta: 0, percent: 0, trimestre: 1 },
          { mes: "Abril", meta: 0, percent: 0, trimestre: 2 },
          { mes: "Maio", meta: 0, percent: 0, trimestre: 2 },
          { mes: "Junho", meta: 0, percent: 0, trimestre: 2 },
          { mes: "Julho", meta: 0, percent: 0, trimestre: 3 },
          { mes: "Agosto", meta: 0, percent: 0, trimestre: 3 },
          { mes: "Setembro", meta: 0, percent: 0, trimestre: 3 },
          { mes: "Outubro", meta: 0, percent: 0, trimestre: 4 },
          { mes: "Novembro", meta: 0, percent: 0, trimestre: 4 },
          { mes: "Dezembro", meta: 0, percent: 0, trimestre: 4 },
        ];
        this.resultTrimestre = [
          { title: "1 Trimestre", valor: 0, percent: 0, trimestre: 1 },
          { title: "2 Trimestre", valor: 0, percent: 0, trimestre: 2 },
          { title: "3 Trimestre", valor: 0, percent: 0, trimestre: 3 },
          { title: "4 Trimestre", valor: 0, percent: 0, trimestre: 4 },
        ];

        dados = {
          FIELDS: "ZXE_META,ZXE_ANOTRI,ZXE_AREA",
          WHERE: `ZXE_TPCALC='UN' AND ZXE_LOJA='${filial.ZXD_LOJA}' AND LEFT(ZXE_ANOTRI,4)='${this.anoEscolhido}' `,
          TABELA: "ZXE",
        };

        const metasBanco = await axios.post(url, dados);

        if (metasBanco.data.length > 0) {
          metasBanco.data.map(e => {
            if (Number(e.ZXE_ANOTRI.substr(4, 2)) === 1) {
              this.metas
                .filter(m => m.trimestre === 1)
                .map(m => {
                  m.meta = stringToBrMoney(e.ZXE_META);
                  m.metaCurr = Number(e.ZXE_META.toFixed(2));
                  m.filial = filial.ZXD_LOJA;
                });
              this.resultTrimestre
                .filter(t => t.trimestre === 1)
                .map(t => {
                  t.valor = stringToBrMoney(e.ZXE_META * 3);
                  t.valorCurr = Number((e.ZXE_META * 3).toFixed(2));
                  t.filial = filial.ZXD_LOJA;
                });
            }
            if (Number(e.ZXE_ANOTRI.substr(4, 2)) === 2) {
              this.metas
                .filter(m => m.trimestre === 2)
                .map(m => {
                  m.meta = stringToBrMoney(e.ZXE_META);
                  m.metaCurr = Number(e.ZXE_META.toFixed(2));
                  m.filial = filial.ZXD_LOJA;
                });
              this.resultTrimestre
                .filter(t => t.trimestre === 2)
                .map(t => {
                  t.valor = stringToBrMoney(e.ZXE_META * 3);
                  t.valorCurr = Number((e.ZXE_META * 3).toFixed(2));
                  t.filial = filial.ZXD_LOJA;
                });
            }
            if (Number(e.ZXE_ANOTRI.substr(4, 2)) === 3) {
              this.metas
                .filter(m => m.trimestre === 3)
                .map(m => {
                  m.meta = stringToBrMoney(e.ZXE_META);
                  m.metaCurr = Number(e.ZXE_META.toFixed(2));
                  m.filial = filial.ZXD_LOJA;
                });
              this.resultTrimestre
                .filter(t => t.trimestre === 3)
                .map(t => {
                  t.valor = stringToBrMoney(e.ZXE_META * 3);
                  t.valorCurr = Number((e.ZXE_META * 3).toFixed(2));
                  t.filial = filial.ZXD_LOJA;
                });
            }
            if (Number(e.ZXE_ANOTRI.substr(4, 2)) === 4) {
              this.metas
                .filter(m => m.trimestre === 4)
                .map(m => {
                  m.meta = stringToBrMoney(e.ZXE_META);
                  m.metaCurr = Number(e.ZXE_META.toFixed(2));
                  m.filial = filial.ZXD_LOJA;
                });
              this.resultTrimestre
                .filter(t => t.trimestre === 4)
                .map(t => {
                  t.valor = stringToBrMoney(e.ZXE_META * 3);
                  t.valorCurr = Number((e.ZXE_META * 3).toFixed(2));
                  t.filial = filial.ZXD_LOJA;
                });
            }
          });
        }

        const anoAnterior = sub(new Date(this.anoEscolhido, 0, 1), {
          years: 1,
        });

        let inicioAnoAnterior = new Date(anoAnterior.getFullYear(), 0, 1);

        let trimestreCont = 1;
        let contMes = 0;
        let valorTrimAnterior = 0;

        await this.CalcUnidadeNegocio(filial.ZXD_LOJA);

        for (let cont = 0; cont <= 12; cont++) {
          const mes = add(inicioAnoAnterior, { months: cont }).getMonth();
          const ano = add(inicioAnoAnterior, {
            months: cont,
          }).getFullYear();

          if (contMes === 3 && trimestreCont === 1) {
            const valor = this.resultTrimestre.filter(
              t =>
                t.trimestre === trimestreCont && t.filial === filial.ZXD_LOJA,
            )[0]?.valorCurr;

            if (valor > 0) {
              this.resultTrimestre
                .filter(
                  t =>
                    t.trimestre === trimestreCont &&
                    t.filial === filial.ZXD_LOJA,
                )
                .map(
                  t => (
                    (t.percent = (
                      (valor * 100) / valorTrimAnterior -
                      100
                    ).toFixed(2)),
                    (t.valorAnterior = valorTrimAnterior)
                  ),
                );
            }
            contMes = 0;
            valorTrimAnterior = 0;
            trimestreCont++;
          }

          if (contMes === 3 && trimestreCont === 2) {
            const valor = this.resultTrimestre.filter(
              t =>
                t.trimestre === trimestreCont && t.filial === filial.ZXD_LOJA,
            )[0]?.valorCurr;

            if (valor > 0) {
              this.resultTrimestre
                .filter(
                  t =>
                    t.trimestre === trimestreCont &&
                    t.filial === filial.ZXD_LOJA,
                )
                .map(
                  t =>
                    (t.percent = (
                      (valor * 100) / valorTrimAnterior -
                      100
                    ).toFixed(2)),
                );
            }
            contMes = 0;
            valorTrimAnterior = 0;
            trimestreCont++;
          }

          if (contMes === 3 && trimestreCont === 3) {
            const valor = this.resultTrimestre.filter(
              t =>
                t.trimestre === trimestreCont && t.filial === filial.ZXD_LOJA,
            )[0]?.valorCurr;

            if (valor > 0) {
              this.resultTrimestre
                .filter(
                  t =>
                    t.trimestre === trimestreCont &&
                    t.filial === filial.ZXD_LOJA,
                )
                .map(
                  t =>
                    (t.percent = (
                      (valor * 100) / valorTrimAnterior -
                      100
                    ).toFixed(2)),
                );
            }
            contMes = 0;
            valorTrimAnterior = 0;
            trimestreCont++;
          }

          if (contMes === 3 && trimestreCont === 4) {
            const valor = this.resultTrimestre.filter(
              t =>
                t.trimestre === trimestreCont && t.filial === filial.ZXD_LOJA,
            )[0]?.valorCurr;

            if (valor > 0) {
              this.resultTrimestre
                .filter(
                  t =>
                    t.trimestre === trimestreCont &&
                    t.filial === filial.ZXD_LOJA,
                )
                .map(
                  t =>
                    (t.percent = (
                      (valor * 100) / valorTrimAnterior -
                      100
                    ).toFixed(2)),
                );
            }
            contMes = 0;
            valorTrimAnterior = 0;
            trimestreCont++;
          }

          if (cont <= 11) {
            const valor = this.metas.filter(
              m =>
                m.trimestre === trimestreCont && m.filial === filial.ZXD_LOJA,
            )[0]?.metaCurr;

            if (valor > 0) {
              this.metas.filter(
                m =>
                  m.trimestre === trimestreCont && m.filial === filial.ZXD_LOJA,
              )[contMes].percent = Number(
                (
                  (valor * 100) /
                    this.tabFaturamento.find(e => Number(e.valor) === mes)[
                      `C-${ano}`
                    ][0] -
                  100
                ).toFixed(2),
              );

              valorTrimAnterior += this.tabFaturamento.find(
                e => Number(e.valor) === mes,
              )[`C-${ano}`][0];
            }
          }

          contMes++;
        }

        const totalArea = this.resultTrimestre.reduce(
          (total, item) => total + item.valorCurr,
          0,
        );

        this.resultadosAreas.push({
          resultTrimestre: this.resultTrimestre,
          filial: filial.ZXD_NOME,
          totalArea,
        });
      }

      this.resultadosAreas.map((e, i) =>
        e.resultTrimestre.map((t, index) => {
          if (t.trimestre > 1) {
            let percentTrimestre =
              (t.valorCurr * 100) /
              this.resultadosAreas[i].resultTrimestre[index - 1].valorCurr;

            percentTrimestre = percentTrimestre - 100;

            this.resultadosAreas[i].resultTrimestre[
              index
            ].percentTrimestre = percentTrimestre.toFixed(2);
          }
        }),
      );
    },
    async CalcUnidadeNegocio(filial) {
      let headersFaturamento = [];
      this.tabFaturamento = [];
      this.mostraCalculo = false;
      this.dialogAguarde = true;
      let url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;

      const anos = sub(new Date(`${this.anoEscolhido}-07-01`), { years: 2 });

      let anosConsulta = [];

      for (let cont = 0; cont <= 2; cont++) {
        if (
          add(anos, { years: cont }).getFullYear() <= new Date().getFullYear()
        ) {
          anosConsulta.push(add(anos, { years: cont }).getFullYear());
        }
      }

      headersFaturamento.push({
        text: "Mês",
        value: "mes",
        align: "start",
      });

      anosConsulta.map(e => {
        headersFaturamento.push({
          text: `${e}`,
          value: `A-${e}`,
          align: "end",
        });
      });

      let dados = {
        FIELDS: "ZXB_ANOMES,ZXB_VALOR",
        INNER: `INNER JOIN ZXB010 ZXB WITH(NOLOCK) ON 
                                            ZXB.D_E_L_E_T_=' ' 
                                            AND ZXB_IDXA=ZXA_ID 
                                            AND ZXB_TIPO='LJ'`,
        WHERE: `ZXA_LOJFIL='${filial}'`,
        TABELA: "ZXA",
      };

      const faturamento = await axios.post(url, dados);

      const meses = [
        { id: "01", title: "Janeiro", value: "00" },
        { id: "02", title: "Fevereiro", value: "01" },
        { id: "03", title: "Março", value: "02" },
        { id: "04", title: "Abril", value: "03" },
        { id: "05", title: "Maio", value: "04" },
        { id: "06", title: "Junho", value: "05" },
        { id: "07", title: "Julho", value: "06" },
        { id: "08", title: "Agosto", value: "07" },
        { id: "09", title: "Setembro", value: "08" },
        { id: "10", title: "Outubro", value: "09" },
        { id: "11", title: "Novembro", value: "10" },
        { id: "12", title: "Dezembro", value: "11" },
      ];

      meses.map(m => {
        this.tabFaturamento.push({
          mes: m.title,
          valor: m.value,
        });
      });

      this.tabFaturamento.map(e => {
        if (e.mes === "Janeiro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}01`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}01`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Fevereiro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}02`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}02`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Março") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}03`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}03`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Abril") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}04`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}04`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Maio") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}05`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}05`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Junho") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}06`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}06`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Julho") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}07`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}07`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Agosto") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}08`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}08`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Setembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}09`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}09`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Outubro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}10`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}10`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Novembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}11`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}11`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
        if (e.mes === "Dezembro") {
          anosConsulta.map(a => {
            e[`A-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}12`)
              .map(f => stringToBrMoney(f.ZXB_VALOR));
            e[`C-${a}`] = faturamento.data
              .filter(f => f.ZXB_ANOMES === `${a}12`)
              .map(f => f.ZXB_VALOR);
            e.ano = a;
          });
        }
      });

      //buscando o faturamento depois de 2023 buscar direto da View

      const anoBi = 2023;

      //faturamento area 04

      url = `${process.env.VUE_APP_BASE_API_URL}/consultaA4`;

      let areas = ``;

      if (this.area[0].area.split(";").length > 1) {
        const splitArea = this.area[0].area.split(";");
        splitArea.map(e => {
          areas += `'${e.trim()}',`;
        });
        areas = areas.substring(0, areas.length - 1);
      } else {
        areas = this.area[0].area.trim();
      }

      if (filial.substr(0, 1) === "0") {
        dados = {
          query: `SELECT SUM(VALOR) VALOR, ANOMES FROM (

                        SELECT FATURAMENTO_LIQUIDO VALOR,ANOMES
                                            FROM DB_V_RECEITAS_BI  WITH(NOLOCK) 

                                            INNER JOIN SA3010 SA3 WITH(NOLOCK) ON A3_COD=CHAVE_VENDEDOR AND A3_FILREF='${filial}' AND A3_ZZLOJRF=''

                                            WHERE 
                                                    LEFT(AREA,6) IN (${areas})
                                                    AND LEFT(ANOMES,4) >= '${anoBi}'
                                                    AND APAC<>'S'
                                                    AND LINHA = 'AUDITIVA'
                                                    AND PLANO_SAUDE<>'1'
                                                    AND RELATORIO_BI<>'2'
                                                    AND CHAVE_VENDEDOR NOT IN ('002491')
                                        
                        
                        
                        UNION ALL
                        
                        SELECT FATURAMENTO_LIQUIDO VALOR,ANOMES
                                            FROM DB_V_RECEITAS_BI  WITH(NOLOCK) 
                                            
                                            INNER JOIN SA3010 SA3 WITH(NOLOCK) ON A3_COD=CHAVE_VENDEDOR AND A3_FILREF='${filial}' AND A3_ZZLOJRF=''
                                            
                                            WHERE 
                                                    
                                                    LEFT(FILIAL,4)='0301'
                                                    AND LEFT(AREA,6) IN (${areas})
                                                    AND LINHA='SERVICOS'
                                                    AND LEFT(ANOMES,4) >= '${anoBi}'
                                        
                        
                        
                        ) AUX
                        WHERE 1=1
                        GROUP BY ANOMES `,
        };
      } else {
        dados = {
          query: `SELECT SUM(VALOR) VALOR, ANOMES FROM (

                        SELECT FATURAMENTO_LIQUIDO VALOR,ANOMES
                                            FROM DB_V_RECEITAS_BI  WITH(NOLOCK) 

                                            INNER JOIN SA3010 SA3 WITH(NOLOCK) ON A3_COD=CHAVE_VENDEDOR AND A3_ZZLOJRF='${filial}'

                                            WHERE 
                                                    LEFT(AREA,6) IN (${this.area[0].area.trim()})
                                                    AND LEFT(ANOMES,4) >= '${anoBi}'
                                                    AND APAC<>'S'
                                                    AND LINHA = 'AUDITIVA'
                                                    AND PLANO_SAUDE<>'1'
                                                    AND RELATORIO_BI<>'2'
                                                    AND CHAVE_VENDEDOR NOT IN ('002491')
                                        
                        
                        
                        UNION ALL
                        
                        SELECT FATURAMENTO_LIQUIDO VALOR,ANOMES
                                            FROM DB_V_RECEITAS_BI  WITH(NOLOCK) 
                                            
                                            INNER JOIN SA3010 SA3 WITH(NOLOCK) ON A3_COD=CHAVE_VENDEDOR AND A3_ZZLOJRF='${filial}'
                                            
                                            WHERE 
                                                    
                                                    LEFT(FILIAL,4)='0301'
                                                    AND LEFT(AREA,6) IN (${this.area[0].area.trim()})
                                                    AND LINHA='SERVICOS'
                                                    AND LEFT(ANOMES,4) >= '${anoBi}'
                                        
                        
                        
                        ) AUX
                        WHERE 1=1
                        GROUP BY ANOMES `,
        };
      }

      const fatReceitaBi = await axios.post(url, dados);

      const anosFatBi = [
        ...new Set(fatReceitaBi.data.map(e => e.ANOMES.substr(0, 4))),
      ];

      this.tabFaturamento.map(e => {
        if (e.mes === "Janeiro") {
          anosFatBi.map(a => {
            if (!a.ANOMES) return;
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}01`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}01`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}01`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Fevereiro") {
          anosFatBi.map(a => {
            if (!a.ANOMES) return;
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}02`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}02`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}02`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Março") {
          anosFatBi.map(a => {
            if (!a.ANOMES) return;
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}03`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}03`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}03`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Abril") {
          anosFatBi.map(a => {
            if (!a.ANOMES) return;
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}04`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}04`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}04`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Maio") {
          anosFatBi.map(a => {
            if (!a.ANOMES) return;
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}05`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}05`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}05`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Junho") {
          anosFatBi.map(a => {
            if (!a.ANOMES) return;
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}06`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}06`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}06`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Julho") {
          anosFatBi.map(a => {
            if (!a.ANOMES) return;
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}07`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}07`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}07`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Agosto") {
          anosFatBi.map(a => {
            if (!a.ANOMES) return;
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}08`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}08`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}08`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Setembro") {
          anosFatBi.map(a => {
            if (!a.ANOMES) return;
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}09`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}09`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}09`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Outubro") {
          anosFatBi.map(a => {
            if (!a.ANOMES) return;
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}10`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}10`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}10`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Novembro") {
          anosFatBi.map(a => {
            if (!a.ANOMES) return;
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}11`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}11`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}11`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
        if (e.mes === "Dezembro") {
          anosFatBi.map(a => {
            if (!a.ANOMES) return;
            const existeData = fatReceitaBi.data.filter(
              f => f.ANOMES === `${a}12`,
            )[0];
            if (!e[`A-${a}`][0]) {
              if (!existeData) {
                e[`A-${a}`] = [];
                e[`C-${a}`] = [];
              } else {
                e[`A-${a}`] = [
                  stringToBrMoney(
                    fatReceitaBi.data
                      .filter(f => f.ANOMES === `${a}12`)
                      .map(f => f.VALOR)[0],
                  ),
                ];
                e[`C-${a}`] = [
                  fatReceitaBi.data
                    .filter(f => f.ANOMES === `${a}12`)
                    .map(f => f.VALOR)[0],
                ];
              }
            }
            e.ano = Number(a);
          });
        }
      });
      this.mostraCalculo = true;
      this.dialogAguarde = false;
    },
  },
  beforeMount() {
    this.buscaIndice();
  },
};
</script>
<style>
.v-data-table.tbmetasOpimed tbody tr td {
  font-size: 0.8rem !important;
  height: 25px !important;
  min-width: 130px;
}
.v-input.inputCalcEspelho {
  background-color: grey !important;
}
.v-input.inputCalcEspelho .v-label {
  margin: 5px;
  color: white;
}
.v-input.inputCalcEspelho input {
  color: white;
}
</style>
